import { computed, Injectable, Signal, signal } from '@angular/core';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { langugaes, langugaesResponse } from '../models/common';

declare global {
  interface Window {
    googleMapsLoaded?: boolean;
  }
}

@Injectable({
  providedIn: 'root',
})
export class InitialService {
  script = document.createElement('script');
  private _languages = signal<langugaes[]>([]);
  constructor(private _authService: AuthService) {}

    async setUpGoogleScript(): Promise<void> {
      const language = localStorage.getItem('language') || 'en';
      if (window.googleMapsLoaded) {
        console.log("Google Maps already loaded.");
        return Promise.resolve();
      }
  
      return new Promise((resolve, reject) => {
        const existingScript = document.getElementById('testScriptName');
        if (!existingScript) {
          this.script.type = 'text/javascript';
          this.script.src = `https://maps.googleapis.com/maps/api/js?v=quarterly&key=${environment.GOOGLE_API_KEY}&libraries=places,geometry&language=${language}`;
          this.script.id = 'testScriptName';
          this.script.async = true;
          this.script.defer = true;
  
          this.script.onload = () => {
            console.log('Google Maps script loaded!');
            window.googleMapsLoaded = true; // Set flag
            resolve();
          };
  
          this.script.onerror = (error) => {
            console.error('Error loading Google Maps script:', error);
            reject('Google Maps API failed to load');
          };
  
          document.head.appendChild(this.script);
        } else {
          console.log('Google Maps script already exists.');
          resolve();
        }
      });
    }

  getLanguages(): void {
    this._authService.languages().subscribe((res: langugaesResponse) => {
      this._languages.set(res.list);
    });
  }

  get languages(): Signal<langugaes[]> {
    return computed(() => this._languages());
  }
}
